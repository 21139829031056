import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "configuration"
    }}>{`Configuration`}</h1>
    <hr></hr>
    <p>{`Hegel initially uses default configuration, but if you want to ignore some files inside your project or tell Hegel that you have a specific environment (`}<a parentName="p" {...{
        "href": "https://nodejs.org/en/"
      }}>{`Node.js`}</a>{` for example) then you need to configure Hegel manually.`}</p>
    <p>{`To eject default configuration run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ hegel init
Project initialized.
`}</code></pre>
    <p>{`After the command is executed `}<inlineCode parentName="p">{`.hegelrc`}</inlineCode>{` file will be created.`}</p>
    <p>{`By default `}<inlineCode parentName="p">{`.hegelrc`}</inlineCode>{` uses `}<a parentName="p" {...{
        "href": "https://yaml.org/"
      }}>{`YAML`}</a>{` format. But you have the ability to choose between YAML and JSON.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`.hegelrc`}</inlineCode>{` consists of 4 sections:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#environment"
        }}>{`environment`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#exclude"
        }}>{`exclude`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#include"
        }}>{`include`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#typings"
        }}>{`typings`}</a></li>
    </ul>
    <h2 {...{
      "id": "environment"
    }}>{`environment`}</h2>
    <p>{`String, which defines the specific environment that should be used. For now only "nodejs" and "browser" options exist. By default Hegel will not include any environment.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`environment: browser
# or
environment: nodejs
`}</code></pre>
    <h2 {...{
      "id": "exclude"
    }}>{`exclude`}</h2>
    <p>{`Array of `}<a parentName="p" {...{
        "href": "https://commandbox.ortusbooks.com/usage/parameters/globbing-patterns"
      }}>{`glob-pattern strings`}</a>{` that match files to be excluded from analysis. By default Hegel excludes all files in the "node_modules" directory.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`exclude:
  - ./node_modules/**
  - ./dist/**
  - specific.js
`}</code></pre>
    <h2 {...{
      "id": "include"
    }}>{`include`}</h2>
    <p>{`Array of `}<a parentName="p" {...{
        "href": "https://commandbox.ortusbooks.com/usage/parameters/globbing-patterns"
      }}>{`glob-pattern strings`}</a>{` that match files that should be analyzed. By default Hegel will analyze all files that end in `}<inlineCode parentName="p">{`.js`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`include:
  - ./**/*.js
  - ./components/*.jsx
  - specific.mjs
`}</code></pre>
    <h2 {...{
      "id": "typings"
    }}>{`typings`}</h2>
    <p>{`Array of paths where Hegel should search for typings. Order is important because Hegel will try to find typings for specific libraries in the order they are defined. By default Hegel will first look inside the local directory "@types" and then inside "./node_modules/@types".`}</p>
    <blockquote>
      <p parentName="blockquote">{`If type definitions are placed inside a specific package you should not define this package path inside this configuration section.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`types:
  - ./@types
  - ./custom-types
  - ./node_modules/@types
  - ./node_modules/custom-types
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Hegel will first try to find type definitions in the directories specified in the `}<inlineCode parentName="p">{`types`}</inlineCode>{` configuration section. If type definitions are not found Hegel will search the `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{` directory. If Hegel can't find type definitions it will infer the types automatically.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      